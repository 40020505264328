export default function animationHome(){


    function floatingGirl(){
        const animation = gsap.to('.floating-girl img', {
          y: 10,
          duration: 2,
          yoyo: true,
          repeat: -1,
          ease: 'none',
        });
    }

    function gatinhoEnave(){
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#atracoes",
                start: "top-=150px 90%", 
                end: "120px 70%", 
                
                scrub: 1
              },
            defaults:{ opacity: 0, ease: "none"}}
            );

          tl
          .from('#o-evento .gatinho-orna  ',  { opacity: 1,  y: 130, duration:1.5})


          gsap.from('#o-evento .alien-orna ', {

            scrollTrigger:{
                trigger: "#atracoes",
                start: "top-=150px 80%",
                end: "120px 20%", 
                
               
                scrub:2
            },
            x: -300,
            opacity:1
          })
          

    }

    function bannerPatrocinio(){match
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#banner-patrocinio",
                start: "top+=50px 80%",
              
                // scrub:1
              },
            defaults:{ opacity: 1, ease: "none"}});

            tl.from('#banner-patrocinio .robo-wrapper',{
               y: 200,              
            })
    }


    function contato(){
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#contato-footer",
                start: "top+=50px 80%",
              
                scrub:2
              },
            defaults:{ opacity: 1, ease: "none"}});
            // gsap.set('#contato-footer .contato-girl-orna',{ y: -100})

            tl.from('#contato-footer .contato-girl-orna', {
                y: -100
            })
    }


    function banner(){
      const tl = gsap.timeline({
        
        defaults: {
          opacity: 0,
        }
        
      })

      gsap.set('#banner-home .logo-wrapper svg > path', {
        stroke: '#3cc5b1',
        fill: 'transparent',
        opacity: 0, 
        y: 40 
      })
      gsap.set('#banner-home .logo-wrapper svg > g path', {
        stroke: '#3cc5b1',
        fill: 'transparent',
        opacity: 0, 
        y: 40 
      })

      tl
      .to('#banner-home .logo-wrapper svg > g path', { 
        opacity: 1, 
         fill: '#fff',
         stroke: '#fff',
         y: 0, 
         ease: 'back',
         stagger:{
           amount: 1.1, 
         }
       })
       .to('#banner-home .logo-wrapper svg > path', { 
         opacity: 1, 
          fill: '#fff',
          stroke: '#fff',
          y: 0, 
          ease: 'back',
          stagger:{
            amount: 0.5, 
          }
        }, '<+=0.5')
      .from('#banner-home  .col-2 > *', {
        y: 40,
        stagger: 0.1
      }, '<+=0.5')
      .from('#banner-home  .socials-banner',{
        x: -100
      },'<')
  
    }


    function patrocinadores(){
      let tl = gsap.timeline({
        scrollTrigger: {
            trigger: "#patrocinadores",
            start: "top 80%"     
            
          },
        defaults:{ opacity: 0, ease: "power1"}});


        tl.from('#patrocinadores .grid-patrocinio .group-wrapper', { y: 40, stagger: 0.2}, '<+=0.3')
        // .from('#patrocinadores .header-wrapper ', {opacity: 0, y: 40})
         
    }

    function scrollSection(){

      const mql600 = window.matchMedia('(max-width:600px)').matches
      let tl = gsap.timeline({
        scrollTrigger: {
            trigger: "#scroll-section",
            // markers: true,
            start: "50% 80%",    
            scrub: 3
          },
        defaults:{ opacity: 1, ease: "power1"}}); 

        gsap.set(".scroll-text-1",{ x: mql600 ? -100 : -200 })
        gsap.set(".scroll-text-2",{ x: mql600 ? 100: 200})
        tl.to(".scroll-text-1", { opacity: 1, x: mql600 ? 50 :100})
        .to(".scroll-text-2", { opacity: 1, x: mql600  ? -50 :-100}, '<');;
    }

    document.addEventListener("DOMContentLoaded", () =>{
      banner();
      patrocinadores();
      scrollSection();
    })
    // gatinhoEnave();
    // bannerPatrocinio();
    // contato();
    // floatingGirl();
}