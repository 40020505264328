export default function modalCertificado() {
  const modal = document.querySelector("#modal-certificado");
  const modalOverlay = document.querySelector("#modal-overlay");
  const closeButton = document.querySelector("#modal-certificado #close-button");
  const openButton = document.querySelectorAll(".open-modal-certificado");

  const oBody = document.body;

  if (modal && modalOverlay && openButton && closeButton) {
    closeButton.addEventListener("click", function () {
      modal.classList.toggle("closed");
      modalOverlay.classList.toggle("closed");
      oBody.classList.toggle("modal-open");
    });

    oBody.addEventListener("click", (e) => {
      const target = e.target.closest(".open-modal-certificado");

      if (!target) return;
      modal.classList.toggle("closed");
      modalOverlay.classList.toggle("closed");
      oBody.classList.toggle("modal-open");
    });

    modalOverlay.addEventListener("click", (e) => {
      modal.classList.add("closed");
      modalOverlay.classList.add("closed");
      oBody.classList.toggle("modal-open");
    });
  }
}
