export default function richTextParse(){
    function parseJSONToHTML(json) {
        const data = JSON.parse(json);
    
        console.log(data)
        
        let html = '';
    
        for (const block of data.blocks) {
          switch (block.type) {
            case 'paragraph':
              html += `<p class="paragraph">${block.data.text}</p>`;
              break;
            case 'Header':
              html += `<h${block.data.level} class="header">${block.data.text}</h${block.data.level}>`;
              break;
            case 'Table':
              html += `<table class="table">${block.data.content}</table>`;
              break;
            case 'Raw':
              html += `<div class="raw">${block.data.html}</div>`;
              break;
            case 'Delimiter':
              html += '<hr class="delimiter">';
              break;
            case 'Code':
              html += `<pre class="code"><code>${block.data.code}</code></pre>`;
              break;
            case 'Quote':
              html += `<blockquote class="quote">${block.data.text}</blockquote>`;
              break;
            case 'List':
              const listItems = block.data.items.map(item => `<li>${item}</li>`).join('');
              const listStyle = block.data.style === 'ordered' ? 'ol' : 'ul';
              html += `<${listStyle} class="list">${listItems}</${listStyle}>`;
              break;
            case 'Embed':
              html += `<div class="embed">${block.data.html}</div>`;
              break;
            case 'Checklist':
              const checklistItems = block.data.items.map(item => {
                const checked = item.checked ? 'checked' : '';
                return `<li>
                <input  type="checkbox" onclick="return false;"  ${checked}>
                <label> ${item.text} </label>
                </li>`;
              }).join('');
              html += `<ul>${checklistItems}</ul>`;
            break;
            case 'Image':
            html += `<img src="${block.data.file.url}" alt="${block.data.caption}" class="image">`;
            break;
            case 'Warning':
                html += `<div class="warning">
                            <h3>${block.data.title}</h3>
                            <p>${block.data.message}</p>
                            </div>`;
            break;
            default:
            // Handle unknown block types here
            break;
          }
        } 
    
        return html;
      }
      const blogContent = document.querySelector('.blog-content')
    
      console.log(blogContent.dataset.conteudo)
      if(blogContent.dataset.conteudo){
        const htmlParsed =  parseJSONToHTML(blogContent.dataset.conteudo)
        console.log(htmlParsed);
        blogContent.innerHTML = htmlParsed
      }else{ 
        console.log('SEM CONTEUDO ;')
      }
     
     
    
    
}