export default function oEvento(){


    // FAQ 
    const faqContent = document.querySelector('.content-faq')

    if(faqContent){
        faqContent.addEventListener('click', (e) => {
            const faqItems =    document.querySelectorAll('.item-faq')
            const faqItem = e.target.closest('.item-faq')

            if(!faqItem) return
                faqItems.forEach(i => i.classList.remove('active'))
                faqItem.classList.add('active')
            
        })
    } 



    // BIGNUMBERS 
    const allNumbers =  document.querySelectorAll('.swiper-numeros .numero-item')

    const arrayClasses = ['badge-cor-1', 'badge-cor-2', 'badge-cor-3', 'badge-cor-4', 'badge-cor-5'];
   

    if(allNumbers){
        allNumbers.forEach((n , index) => {
            n.querySelector('.badge-cor').classList.add();
            n.querySelector('.badge-cor').classList.add(arrayClasses[index % arrayClasses.length]);
        })
    }

    const swiperNumeros = new Swiper('.swiper-numeros', {

        slidesPerView: 'auto',
        allowTouchMove:false,
        speed: 4000,
        loop: true,
        autoplay:{
            delay: 0,           
        }
    })
} 