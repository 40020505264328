export default function programacaoApi(inHome = false){

    const listDatas = document.querySelectorAll('.lista-datas-js');
    const programacoesWrapper = document.querySelector('.programacoes');
    const programacaoWrapperHome = document.querySelector('.programacao-tab-content .grid-wrapper');


  //   const bkp = `
          //   <div class="programacao">
          //   <div class="horario-wrapper">
          //     <p> ${horaInicial ? horaInicial : ''} ${horaFinal ?  '-' + horaFinal : ''}  ${item.info.minuto ? '| ' + item.info.minuto + 'min' : ''}</p>
          //   </div>


          //   <div class="dia-atracoes-wrapper">

          //     <div class="jornada-wrapper">
          //       <p class="mesa-redonda">${item.info?.titulo}</p>
          //       <h2 class="jornada-title"> ${item.info?.descricao} </h2>


          //       <div class="infos-sala-wrapper">
          //         <div class="col-1">
          //           <div class="tags">
          //           ${
          //             tags && tags.map(tag => {
          //               return  `<span>${tag}</span>`
          //             }).join('')
          //           }
                   
          //           </div>

          //           <div class="local-wrapper">
          //           ${item.info?.local && `
          //           <div class="local">
          //             <svg>
          //               <use xlink:href="#icon_local"></use>
          //             </svg>
          //             <p> <span>Local</span>:  ${item.info?.local}</p>
          //           </div>
          //           <div class="line-grey"></div>
                   
          //           `}
          //             <div class="local">
                        
          //                 ${item.info?.sala &&  `<svg><use xlink:href="#icon-local"></use></svg>
          //               <p> <span>Sala</span>: ${item.info.sala}</p>` }
                       
          //             </div>

          //           </div>
          //         </div>
                  
          //         ${item.info.imagem ? 
          //           ` <div class="line-grey"></div>
          //             <div class="col-2">
    
          //               <!-- MODERADOR -->
          //               <p class="mod-text">Moderador</p>
          //               <div class="debate-item">
          //                 <div class="debate-img">
          //                   <img src="${item.info?.imagem}" alt="">
          //                 </div>
          //                 <div>
          //                   <div class="debate-infos">
          //                     <p class="debate-nome">${item.info?.nome ? item.info?.nome : ''}</p>
          //                     <p class="debate-info"> ${item.info?.funcao ? item.info?.nome : ''}</p>
          //                   </div>
          //                 </div>
          //               </div>
          //             </div>
          //           `
          //           : ''}
          //       </div>

          //       <div class="descricao-new hidden">
          //          Simplificação e Desburocratização em Licenciamentos para Edificações
          //         </div>

          //     </div>

          //     <div class="debatedores-grid">


          //     ${
          //       item.data_atracoes.length !== 0 ? `<p class="debate-text">Debatedores</p>` : ''
          //     }
                

          //       <div class="debatedores-wrapper">

          //       ${item.data_atracoes.map(atracao =>{
          //         return `
          //           <div class="debate-item" data-id="${atracao.id}">
          //             <div class="debate-img">
          //               <img src="${atracao.imagem}" alt="${atracao.nome}">
          //             </div>
          //             <div>
          //               <div class="debate-infos">
          //                 <p class="debate-nome">${atracao.nome}</p>
          //                 <p class="debate-info">${atracao.funcao}</p>
          //               </div>
          //             </div>
          //           </div>
          //         `
          //       }).join('')}
                 

          //       </div>
          //     </div>
          //   </div>
          // </div>
          //   `;
    function convertToCustomFormat(timeString) {
      // Split the timeString into hours and minutes using the colon as a separator
      const [hours, minutes] = timeString.split(':');
    
      // Create the custom format by concatenating the hours with 'H' and the minutes
      const customFormat = `${hours}H${minutes}`;
    
      return customFormat;
    }

    
    function renderProgramacao(data){

        if(!data) return
        console.log(data)

        const sortedData = data.sort((a, b) => a.info.horario.localeCompare(b.info.horario));
       

        const programacaoHTML = sortedData.map(item => {
          const tags =  item.info.tag && item.info.tag.split(',')
          const horaInicial = item.info.horario && convertToCustomFormat(item.info.horario)
          const horaFinal = item.info.termino ? convertToCustomFormat(item.info.termino) : false
          console.log(horaFinal)
            return `

            <div class="programacao">
            <div class="horario-wrapper">
              <p> ${horaInicial ? horaInicial : ''} ${horaFinal ?  '-' + horaFinal : ''}  ${item.info.minuto ? '| ' + item.info.minuto + 'min' : ''}</p>
            </div>


            <div class="dia-atracoes-wrapper">

              <div class="jornada-wrapper">
                <p class="mesa-redonda">${item.info?.titulo}</p>
                <h2 class="jornada-title"> ${item.info?.descricao} </h2>


                <div class="infos-sala-wrapper">
                  <div class="col-1">
                    <div class="tags">
                    ${
                      tags && tags.map(tag => {
                        return  `<span>${tag}</span>`
                      }).join('')
                    }
                   
                    </div>

                    <div class="local-wrapper">
                      <!-- ${item.info?.local && `
                        <div class="local">
                          <svg>
                            <use xlink:href="#icon_local"></use>
                          </svg>
                          <p> <span>Local</span>:  ${item.info?.local}</p>
                        </div>
                        ${item.info?.sala ? `<div class="line-grey"></div>` : ''}
                      `} -->
                      
                      <div class="local">
                        ${item.info?.sala &&  `
                          <svg><use xlink:href="#icon-local"></use></svg>
                          <p> <span>Sala</span>: ${item.info.sala}</p>
                        `}
                      </div>
                    </div>
                  </div>
                  
                
                </div>

                <div class="descricao-new hidden">
                   Simplificação e Desburocratização em Licenciamentos para Edificações
                  </div>

              </div>

              <div class="debatedores-grid">

              ${item.info?.richtext? 
                `
                <div class="richText-info">

                    ${item.info?.richtext}

                   

                    </div>
                ` : ''
              }

              </div>
            </div>
          </div>
            `;
        }).join('');

        if(programacoesWrapper){
          programacoesWrapper.innerHTML = programacaoHTML;
        }

        const  swiperProgramacaoAtracoes = new Swiper('.swiper-programacao-atracoes',{
            slidesPerView: 1.6,
            spaceBetween: 8,
            
        
            breakpoints:{
              768:{
                slidesPerView: 3.8,
              },
              1440:{
                slidesPerView: 4.5,
              },
            },
            pagination: {
              el: '.swiper-programacao-atracoes .swiper-pagination'
            },
            navigation: {
              nextEl: '.dia-atracoes-wrapper .button-right',
              prevEl: '.dia-atracoes-wrapper .button-left',
            },
            
        });
    };

    function renderProgramacaoInHome(data){
      if(!data) return 
       

      const programacaoHTML = data.map(item => {

        // /static/front_assets/src/assets/imagens/carpa.webp
        return `
        <div class="side-info">

        <div class="content-side">
          <div class="img-detail">
          
            <img src="static/front_assets/src/assets/imagens/icons/flor-dali-xl.png" alt="">
          </div>
          <h2 class="programacao-horario">
            Às ${item.info.horario} 
          </h2>
          <h2 class="programacao-local">
          
          ${item.info.local}
          </h2>
        </div>


        <div class="bottom-info">
          <a href="/programacao" class="button-bottom-info">
            VER PROGRAMAÇÃO COMPLETA
            <svg>
              <use xlink:href="#icon_diag-black"> </use>
            </svg>
          </a>
        </div>

      </div>
      <div class="programacao-dia-wrapper">

        <div class="dia-info-wrapper">
          <div class="title">${item.info.titulo} </div>
          <div class="content-for-p"> 
            <p class="descricao ">
              ${item.info.descricao}
            </p>
            <p class="continuar-lendo">
              Continuar lendo

              <svg>
                <use xlink:href="#icon_plus"></use>
              </svg>

            </p>
          </div> 
        </div>
        <div class="dia-atracoes-wrapper">
          <div class="header-atracoes">
            <p class="header-atracoes-titulo">Atrações</p>
            <div class="swiper-controls-header">

              <div class="pagination"></div>
              <div class="buttons">
                <div class="button button-left">
                  <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.23828 6.28851L0.76125 3.81248L3.23828 1.33645" stroke="#E2E8ED"
                      stroke-linecap="round" stroke-linejoin="round" />
                  </svg>


                </div>
                <div class="button button-right">
                  <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.760742 1.33643L3.23777 3.81246L0.760742 6.28849" stroke="#0F0F12"
                      stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper swiper-programacao-atracoes">
            <div class="swiper-wrapper">
            ${item.data_atracoes.map(atracao =>{

              return `
              
                  <div class="swiper-slide">
                      <div class="card-programacao card-evento open-modal-atracao" data-id="${atracao.id}">
                      <div class="card-evento-img">
                          <img src="${atracao.imagem}" alt="${atracao.nome}">
                      </div>
                      <a href="#" class="content">
                          <h2 class="card-evento-title">${atracao.nome}</h2>
                          <p class="card-evento-role">${atracao.funcao}</p>

                      </a>
                      </div>
                  </div>
              `;
            }).join('')} 

             
            </div>
          </div>
        </div>
      </div>
        `
      }).join('');

      if(programacaoWrapperHome){
       
        programacaoWrapperHome.innerHTML = programacaoHTML;
      }

      const  swiperProgramacaoAtracoes = new Swiper('.swiper-programacao-atracoes',{
        slidesPerView: 1.3,
        spaceBetween: 8,
  
        breakpoints:{
          768:{
            slidesPerView: 3,
          }
        },
    
        navigation: {
          nextEl: '.dia-atracoes-wrapper .button-right',
          prevEl: '.dia-atracoes-wrapper .button-left',
        },
        
      });

    }


    function getProgramacao(data){


        if(!data) return
        const url = `/home/api/programacao-dia/?data=${data}`

        
        axios.post(url)
            .then((response) => {

                
                if(response.data){
                    
                  
                  if(inHome){
                    renderProgramacaoInHome(response.data);
                  }else{
                    renderProgramacao(response.data)
                  }
                }
            })
            .catch((response) => {
                console.log(response)
                
            });
    }



    listDatas && listDatas.forEach(data => {
        data.addEventListener('click', (e) => {
            const target = e.target.closest('.lista-datas-js')
            if(!target) return
            listDatas.forEach(i => i.classList.remove('active'))
            target.classList.add('active')
            const data = target.dataset.diaEvento
            
            getProgramacao(data);


        })
    })

    const primeiraData = document.querySelector('.lista-datas-js')
    if(primeiraData){
        const data = primeiraData.dataset.diaEvento
        if(data){
            getProgramacao(data)
        }
    }
}