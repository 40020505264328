export default function menu() {
  const btnMobile = document.getElementById("btn-mobile");
  const headerEl = document.getElementById("header");

  const isHome = document.querySelector(".body-home");
  const cepCnpj = document.querySelector("#cep-cnpj-js");

  const formMenu = document.querySelectorAll(".form-menu");
  const linksMenu = document.querySelectorAll("#menu a.link");

  function toggleMenu(event) {
    if (event.type === "touchstart") event.preventDefault();
    const nav = document.getElementById("nav");

    document.body.classList.toggle("menu--open");
    nav.classList.toggle("active");
    const active = nav.classList.contains("active");
    event.currentTarget.setAttribute("aria-expanded", active);

    if (active) {
      event.currentTarget.setAttribute("aria-label", "Fechar menu");
    } else {
      event.currentTarget.setAttribute("aria-label", "Abrir menu");
    }
  }

  if (linksMenu) {
    linksMenu.forEach((link) => {
      link.addEventListener("click", () => {
        const nav = document.getElementById("nav");
        nav.classList.remove("active");
        document.body.classList.remove("menu--open");
      });
    });
  }

  // console.log(gsap)
  // console.log(btnMobile)
  // console.log(headerEl)
  if (btnMobile) {
    btnMobile.addEventListener("click", toggleMenu);
    if (headerEl) {
      window.addEventListener("scroll", function () {
        headerEl.classList.toggle("sticky", window.scrollY > 150);
      });
      // if(isHome){

      // }else{
      //     headerEl.classList.add('sticky');
      //     headerEl.classList.add('is-not-home')
      // }
    }
  }

  function clickItemRodada(){
    const rodadaA = document.querySelector('#nav li a[href*="rodada"]')
    if(!rodadaA) return
    const rodadaLi = rodadaA.closest('li')
    const rodadaNav = document.querySelector("[data-content='tab-content-6']")
    const secaoInscricao = document.querySelector('#inscricao')

    // console.log(rodadaNav);
    rodadaLi && rodadaLi.addEventListener('click', (e)=>{
        e.preventDefault()
        window.scrollTo(0, secaoInscricao.offsetTop)
        $(rodadaNav).click()
    })
  }
  document.addEventListener("DOMContentLoaded", clickItemRodada);

  

  let previousScrollPosition =
    window.pageYOffset || document.documentElement.scrollTop;

  window.addEventListener("scroll", function () {
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;

    if (currentScrollPosition < previousScrollPosition) {
      // Scrolled up
      // console.log("Scrolled up");
      if (headerEl) {
        headerEl.classList.remove("sticky");
      }
    } else {
      // Scrolled down or no change
      // console.log("Scrolled down or no change");
    }

    previousScrollPosition = currentScrollPosition;
  });
}
