export default function singleBlog(){

    const mql768 = window.matchMedia('(max-width:768px)').matches
    const swiperMidia = new Swiper('.swiper-midia',{
        slidesPerView: 1.2,
        spaceBetween: 16
    })

    if (!mql768) {
      // Destroy the Swiper instance
      swiperMidia.destroy();
    }
 
}


