export default function animationOevento(){

    function intro(){
       
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#o-evento",
                start: "top+=50px bottom",
              
                // scrub:1
              },
            defaults:{ opacity: 0, ease: "back"}});

              
            tl
            .from('.linear img', {
               scaleX: 0.9,
               ease: 'none'
            })
            .from('#o-evento .vertical-items-wrapper > *', {
                y: 50,
                stagger: 0.2,
                duration: 1,
               
            }, '-=0.2')

            gsap.from('#o-evento .col-2 ', { x: 200 , delay: 1.2, opacity: 0})
            gsap.from('#o-evento  .gatinho-orna', { x: 100, delay: 1.5})
    }


    intro();
}