export default function animationQuemSomos(){

const isMobile =window.matchMedia('(max-width: 1024px)').matches
    function bannerIntro(){

        if(isMobile){
            return
        }
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#quem-somos",
                start: "top+=10px 90%", 
             
               
                
              },
            defaults:{ opacity: 0, ease: "back", opacity: 0}});
        
         gsap.set('.content-for-sobre', { 'clip-path':  'inset( 0 50%)', width: 0, height: 0, })

         tl.from('#quem-somos .section-title > *', { y: 50, duration: 1, stagger: 0.2})
         .from('.info-evento',{ rotate: 180, ease: 'none', duration: 0.5}, '<+=0.5')
         .to('.content-for-sobre', {   width: '100%', duration: 0.6, opacity: 1}, '-=0.4')
         .to('.content-for-sobre',{  height: 'auto', duration: 0, opacity: 1}, '<+=0.4'  )
         .to('.content-for-sobre', { 'clip-path':  'inset( 0 0%)',  opacity: 1 } ,'<')
         .from('#fotos', { y: 100, opacity: 0}, 1)
        //  .to('.info-evento',{ 'justify-content': 'space-between', ease: 'none', opacity: 1, duration: 1} )
         
        //  .to('.content-for-sobre', { 'clip-path':  'inset( 0 100% 0 0)', width: '100%' ,  opacity: 1 }, '<+=0.5')
        //  .to('.content-for-sobre',{  height: 'auto', duration: 0, opacity: 1}, '<+=0.3' )
        //  .to('.content-for-sobre', { 'clip-path':  'inset( 0 0% 0 0)',  opacity: 1 }, '<')
    }
    bannerIntro();
}