export default function homejs(){
    
    
  const mql1024 = window.matchMedia('(max-width:1024px)').matches

  // TABS PROGRAMACAO 
  const allTabs = document.querySelector('.inscricao-tabs') 
  const allTabsItem = document.querySelectorAll('.inscricao-tabs .tab-inscricao') 
  const tabContents = document.querySelectorAll('.inscricao-tab-content')




    if(allTabs){
        allTabs.addEventListener('click', (e)=>{
          // console.log(e.target)
          const tab = e.target.closest('.tab-inscricao');
          if(!tab) return
          // console.log(tab)
          allTabsItem.forEach(item => item.classList.remove('active'));

          tab.classList.add('active')
          const tabId = tab.dataset.content;

          tabContents.forEach(t => t.classList.remove('active'))
          const contentToShow = document.querySelector(`#${tabId}`)
          // console.log(tabId)
          contentToShow.classList.add('active')
        })
    }



    // readmore programacao 
    
    const readmore = document.querySelectorAll('.continuar-lendo')

    readmore.forEach(item => {
      item.addEventListener('click',(e) => {
        const parent = e.target.closest('.dia-info-wrapper')
        if(parent){
          parent.classList.toggle('show')
        }
      })
    })


     
   


    const  swipertabs = new Swiper('.inscricao-tabs',{
        slidesPerView: 'auto',
        spaceBetween: 32,
        slideToClickedSlide: true,
        breakpoints:{
          1024:{
            slidesPerView: 6,
          }
        },     
      });
    
      const swiperPalestrantes = new Swiper('.swiper-palestrantes-mobile',{
        slidesPerView: 1.1,
        spaceBetween: 16,
        slideToClickedSlide: true,
        breakpoints:{
       
          768:{
            slidesPerView: 2.2,
            spaceBetween: 16,
          }
         
        }
      });

      if (window.innerWidth >= 1024) {
        // Destroy the Swiper instance
        if (typeof swiperPalestrantes.destroy === "function") {

          // swiperPalestrantes.destroy();
        }
      
      }

   

    // insert content instagram



    

}