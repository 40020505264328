export default function baseComingSoon(){
    const contatoFormSubmit = document.querySelector('#coming_soon_form')
    contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function(event) {
        event.preventDefault();
        disabledBtn()
        const form_data = {
            email: document.querySelector('#email').value,
        }
        const url = "/cadastro_coming_soon/"
        axios.post(url, form_data)
            .then((response) => showMessage(response))
            .catch((response) => showMessage(response));
    });

    function showMessage(response) {
        const message = response.data ? response.data.message : response.message
        GLOBAL.showToastify(message)
        contatoFormSubmit.reset()
        setTimeout(enabledBtn, 1000)
    }

    const btnSubmit = document.querySelector(".btnzin_subnaits")

    function disabledBtn() {
        btnSubmit.disabled = true
        btnSubmit.classList.add("disabled")
    }

    function enabledBtn() {
        btnSubmit.disabled = false
        btnSubmit.classList.remove("disabled")
    }
}