export function share(){

    const facebookIcon =document.querySelector('#share-facebook')
    const linkedinIcon = document.querySelector('#share-linkedin')
    const twitterIcon = document.querySelector('#share-twitter')

    const copyIcon = document.querySelector('#share-icon');

    function sharePost(platform) {
        // Get the current post URL or customize it as needed
        const postURL = window.location.href; // Use the current page URL or provide a specific URL
        
        // Define the share URLs for each platform
        const shareURLs = {
          facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postURL)}`,
          twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(postURL)}`,
          linkedin: `https://www.linkedin.com/sharing/share-offsite/?mini=false&url=${encodeURIComponent(postURL)}&title=${document.title}`
        //   linkedin: `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(postURL)}`
        };
        
        // Open the share URL in a new window or tab
        window.open(shareURLs[platform], '_blank');
      }


      function copyText() {
       

        const postURL = window.location.href;
        navigator.clipboard.writeText(postURL)
          .then(() => {
            console.log('Text copied successfully!');
            // Optionally, show a success message to the user
            GLOBAL.showToastify('Link copiada com sucesso')
          })
          .catch((error) => {
            console.error('Tente novamente: ', error);
            // Optionally, show an error message to the user
          });
      }
     




      document.addEventListener("DOMContentLoaded", () => {


   
        facebookIcon.addEventListener('click', () => sharePost('facebook'));
        linkedinIcon.addEventListener('click', () => sharePost('linkedin'));
        twitterIcon.addEventListener('click', () => sharePost('twitter'));
        copyIcon.addEventListener('click', copyText);
      })

}