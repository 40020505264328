export default function animationCredenciamento(){


    function bannerIntro(){

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#banner-credenciamento",
                start: "top+=10px 90%", 
             
               
                
              },
            defaults:{ opacity: 0, ease: "back", opacity: 0}});

            gsap.set('#banner-credenciamento .main-img-wrapper', {  'clip-path': 'circle(0%)'})

            tl.from('#banner-credenciamento .section-title > *', { y: 50, duration: 1, stagger: 0.2})
            tl.from('#banner-credenciamento .col-2 > *', { y: 50, duration: 1, stagger: 0.2}, '<')
            tl.to('#banner-credenciamento .main-img-wrapper', {  'clip-path': 'circle(100%)', opacity: 1, duration: 0.8, ease: 'none'}, '<+=0.4' )

    }


    bannerIntro();
}